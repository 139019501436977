import React from 'react'
import blueStyles from '../components/blue.module.css'
import ChatBot from '../components/chatbot'
import {SiteTitle} from '../components/linksetc.js'
import Layout from '../components/layout'



const NotFoundPage = () => (

<>

<SiteTitle string="404" />

<Layout>

<div className={blueStyles.blue}>

  <h1>404</h1>
  <p>Somehow you found a page that does not exist.</p>
  <p>Write your way out of this mess (that's what I always do).</p>
    <ChatBot/>


</div>

</Layout>

</>

)

export default NotFoundPage
